/**
 * 接口守卫
 * 
 * customConfig - 自定义配置
 *    - disableAuth：禁用 验证登录状态
 *    - disableTips：禁用 全局接口默认提示
 *    - disableErrCode：禁用 全局状态码判断
*/
import { apiLogoutUrl } from '@/api/user';
import i18n from '@/i18n';
import store from '@/store';
import axios from 'axios';
import { isMock } from './constants';
import { getGameInfoByUrl } from './game';
import { tipsError } from './message';
import { getSource, removeUserInfo } from './storage';
import { isMobile } from './utils';


const pendingRequests = new Map();

const instance = axios.create({
  baseURL: "/api",
  timeout: 20000
})

instance.interceptors.request.use(
  (config) => {
    const { disableAuth } = config.customConfig || {}
    const { isLogin } = store.getters
    const { country, lang } = store.state.game
    const { gameZoneCode, gameRoleCode, userSource } = store.state.user.userInfo
    const { gameCode } = getGameInfoByUrl()

    isMock && console.log(config.url)

    const key = config.url + '&' + JSON.stringify(config.params);
    if (pendingRequests.has(key)) {
      pendingRequests.get(key).cancel()
    }
    config.cancelToken = new axios.CancelToken(cancel => {
      pendingRequests.set(key, { cancel })
    })

    if (!isLogin && !disableAuth) {
      return Promise.reject({
        code: 12000,
        message: 'auth fail',
        cancelToken: 'auth fail',
      })
    }

    config = {
      ...config,
      headers: {
        'game-code': gameCode,
        'device-type': isMobile() ? 'm' : 'pc',
        'user-source': isLogin ? userSource : getSource(),
        'app-language-code': lang,
        ...config.headers
      },
      params: {
        regionCode: country,
        languageCode: lang,
        gameRoleCode,
        gameZoneCode,
        ...config.params,
      }
    }

    return {
      ...config,
      // withCredentials: true
    }

  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    const { url, params, customConfig } = response.config
    let data = {}
    const key = url + '&' + JSON.stringify(params);
    
    if (pendingRequests.has(key)) {
      pendingRequests.delete(key);
    }

    isMock && console.log(response.data)

    if (response.status === 200) {

      data = response.data

      if(!customConfig?.disableErrCode) {

        if (data.code === 12000) {    // 登录过期
          removeUserInfo()
          window.location.href = apiLogoutUrl()
          tipsError(i18n.t('sys.warn.authFail'))
          return
        }

        if (data.code === 12001) {    // 登录验证失败
          store.dispatch('user/logout')
          tipsError(i18n.t('sys.warn.authVerifyFail'))
          return
        }
  
        if(data.code === 13001) {     // 系统维护时候
          location.reload()
          return
        }

      }
      
      /* 
      * 添加新的全局错误码判断
      * 考虑点：
      *   1. 错误提示是对特殊接口造成拦截：登录、注销 等
      *   2. 使用 tipsError 是否会影响 element-ui 的 tips 弹层遮挡
      * 备注：
      *   改后请告知一下其他开发
      */

      
      // if (data.code?.toString().indexOf('50') > -1) {
      //   tipsError(i18n.t('sys.warn.serviceError'))
      //   return
      // }

      if(data.code != 0) {
        !customConfig?.disableTips && tipsError(data.message)
      }
    }

    return data
  },
  (error) => {
    const msg = error?.toString()

    // if (error?.cancelToken === 'auth fail') {
    //   store.dispatch('user/logout')
    // }

    if(error?.response?.status === 503) {
      window.location.href = '/maintenance.html'
    }

    if (msg?.indexOf('Network') > -1) {
      tipsError(i18n.t('sys.warn.networkError'))
    }

    if (msg?.indexOf('timeout') > -1) {
      tipsError(i18n.t('sys.warn.timeoutError'))
    }

    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      return Promise.reject(error)
    }

  }
)


export default instance
