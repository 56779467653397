import Mock from 'mockjs'

// Mock.setup({
//     timeout: 2000
// })

Mock.mock(
    RegExp('.*/app/order/page.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": {
            "pageNum": 1,
            "pageSize": 10,
            "total": 4,
            "records": [
                {
                    "orderCode": "P2024073149054864057004932",
                    "createTime": 1721614452169,
                    "commodityName": "代金券x30-台湾-繁体代金券x30-台湾-繁体代金券x30-台湾-繁体代金券x30-台湾-繁体代金券x30-台湾-繁体",
                    "commodityNum": 1,
                    "orderNeedPayPrice": 33.00,
                    "currency": "TWD",
                    "currencySymbol": "NT$",
                    "currencyFrontLocation": "10",
                    "orderStatus": 11,
                    "canCancel": true
                },
                {
                    "orderCode": "2024071944704804139462656",
                    "createTime": 1721376831710,
                    "commodityName": "代金券x30-香港-繁体",
                    "commodityNum": 1,
                    "orderNeedPayPrice": 31.00,
                    "currency": "HKD",
                    "currencySymbol": "$",
                    "currencyFrontLocation": "10",
                    "orderStatus": 1,
                    "canCancel": true
                },
                {
                    "orderCode": "2024071944704602355691520",
                    "createTime": 1721376783622,
                    "commodityName": "代金券x6-香港-繁体",
                    "commodityNum": 1,
                    "orderNeedPayPrice": 8.00,
                    "currency": "HKD",
                    "currencySymbol": "$",
                    "currencyFrontLocation": "10",
                    "orderStatus": 0,
                    "canCancel": true
                },
                {
                    "orderCode": "2024071944697489764020224",
                    "createTime": 1721375087941,
                    "commodityName": "代金券x30-香港-繁体",
                    "commodityNum": 1,
                    "orderNeedPayPrice": 31.00,
                    "currency": "HKD",
                    "currencySymbol": "$",
                    "currencyFrontLocation": "10",
                    "orderStatus": 0,
                    "canCancel": true
                }
            ],
            "hasMore": true
        }
    }
)

Mock.mock(
    RegExp('.*/app/order/cancel.*'),
    {
        "code": 0,
        "message": "訂單已完成，不支援取消，請剧新訂單查看"
    }
)

Mock.mock(
    RegExp('.*/app/order/listOrderLock.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": [
            {
                "orderCode": "P2024072346143921011560448",
                "commodityName": "测试商品1",
                "commodityNum": 1,
                "createTime": 1731642957388,
                "lockType": 1
            },
            {
                "orderCode": "P2024082346143921011560448",
                "commodityName": "测试商品2",
                "commodityNum": 10,
                "createTime": 1731642957388,
                "lockType": 1
            },
            {
                "orderCode": "P2024102346143921011560448",
                "commodityName": "测试商品3",
                "commodityNum": 11,
                "createTime": 1731642957388,
                "lockType": 1
            }
        ]
    }
)

Mock.mock(
    RegExp('.*/app/order/cancelOneClick.*'),
    {"code":10001,"message":"参数错误:orderCodes"}
)