import Mock from 'mockjs'

Mock.mock(
    RegExp('.*/app/commodity/list.*'),
    {
        "code": 0,
        "message": "Operation successful",
        "data": [
            {
                "gameCode": "1729141272294",
                "commodityCode": "5217",
                "commodityName": "6 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/2ec8e204-9119-419d-b9df-311a2817f5e3.png",
                "commodityOriginalPrice": 49,
                "commoditySellPrice": 49,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×18",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ],
                                "cards": [
                                    {
                                        "id": 65,
                                        "cardNo": "************3667",
                                        "cardBrand": "MASTERCARD"
                                    }
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": true,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5218",
                "commodityName": "12 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/7c9f3ea3-c991-49c6-b37a-58bdc1a61712.png",
                "commodityOriginalPrice": 90,
                "commoditySellPrice": 90,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×36",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5219",
                "commodityName": "30 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/225cea47-8ab2-4f0f-b057-69ff84e16a45.png",
                "commodityOriginalPrice": 299,
                "commoditySellPrice": 299,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×90",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5220",
                "commodityName": "98 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/3765e3b3-9942-4272-8642-df6c1be576f8.png",
                "commodityOriginalPrice": 799,
                "commoditySellPrice": 799,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×294",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5221",
                "commodityName": "198 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/626c70f2-2bb8-4f33-883f-cf53a1febe0f.png",
                "commodityOriginalPrice": 1790,
                "commoditySellPrice": 1790,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×594",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5222",
                "commodityName": "328 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/218bb814-ed6b-43f1-92b6-8fd234762ba2.png",
                "commodityOriginalPrice": 2990,
                "commoditySellPrice": 2990,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×984",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            },
            {
                "gameCode": "1729141272294",
                "commodityCode": "5223",
                "commodityName": "648 Voucher",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241031/6121b36a-0fe1-4f1b-9d1f-22602c8cfe2f.png",
                "commodityOriginalPrice": 4990,
                "commoditySellPrice": 4990,
                "commodityCurrency": "PHP",
                "commodityCurrencySymbol": "₱",
                "currencyFrontLocation": "10",
                "preferentialExplain": "Jade Bonus×1944",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "CARD",
                                "payTypeName": "Card",
                                "payLevel": 2,
                                "needValid": false,
                                "supportDetails": [
                                    "VISA",
                                    "MASTERCARD"
                                ]
                            },
                            {
                                "payType": "GCASH",
                                "payTypeName": "GCash",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 100000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 0.79
                            }
                        ]
                    },
                    {
                        "payType": "MYCARD",
                        "payTypeName": "MyCard",
                        "payLevel": 1,
                        "needValid": true,
                        "amountLimitStart": 0,
                        "amountLimitEnd": 1000000
                    }
                ],
                "isRecommend": false,
                "promotionCode": "2024110182737897483821056"
            }
        ]
    }
)


Mock.mock(
    RegExp('.*/app/order/createOrderThenPay.*'),
    {
        "code": 0,
        "data": {
            "orderCode": "112312121",
            "appLinkUrl": "https://www.baidu.com",
            "normalLinkUrl": "https://www.baidu.com",
            "waitSecond": 100
        },
        "message": "错误信息"
    }
)

Mock.mock(
    RegExp('.*/app/order/getPayResult.*'),
    {"code":0,"message":"การดำเนินการสำเร็จ","data":{"timeRemain":464}}
)


Mock.mock(
    RegExp('.*/api/app/order/deleteCardSaved.*'),
    {"code":0,"message":"成功"}
)

