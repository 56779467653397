import instance from '@/utils/request'
import { deleteTicket } from '@/utils'
import { getGameInfoByUrl } from '@/utils/game'
import { getRedirectUrl } from '@/utils/storage'

export const apiLoginUrl = (lang) => {
    const { gameCode, loginKey } = getGameInfoByUrl()
    const href = encodeURIComponent(getRedirectUrl() || location.href.replace(/\?.*/,'').replace(/\/record|payResult/, '/index'))
    lang = lang?.indexOf('-') > -1 ? lang.replace('-', '_') : lang + '_' + lang?.toLocaleUpperCase()

    if(lang === 'id_ID') lang = 'in_ID'

    return deleteTicket(`${process.env.VUE_APP_LOGIN_PATH}/external/interfaceLogin?userLanguage=${lang}&key=${loginKey}&game=${gameCode}&s=${href}`)
}

export const apiLogoutUrl = () => {
    return deleteTicket(`${process.env.VUE_APP_LOGIN_PATH}/external/logout?b=${encodeURIComponent(location.href)}`)
}

export const apiLogin = function (params) {
    return instance({
        method: 'GET',
        url: '/app/auth/login',
        params,
        customConfig: {
            disableAuth: true,
            disableTips: true,
            disableErrCode: true
        }
    })
}

export const apiUserInfo = function (params) {
    return instance.get('/app/user/getUser', {
        params,
        customConfig: {
            disableAuth: true
        }
    })
}

export const apiLogout = function () {
    return instance.get('/app/auth/logout', {
        customConfig: {
            disableAuth: true
        }
    })
}

export const apiRoleList = function () {
    return instance.get('/app/user/listGameRoles')
}

export const apiBindRole = function (gameZoneCode, gameRoleCode) {
    return instance('/app/user/bindGameRole',{
        params: {
            gameZoneCode,
            gameRoleCode
        }
    })
}

