import Mock from 'mockjs'

Mock.mock(
  RegExp('.*/app/auth/login.*'),
  {
    "code": 12001,
    "data": null,
    "message": "登录信息验证失败"
  }
)

Mock.mock(
  RegExp('.*/app/user/getUser.*'),
  {
    "code": 0,
    "message": "操作成功",
    "data": {
        "userCode": "1**3",
        "userName": "123",
        "userSource": "others",
        "gameRoleBound": {
            "gameCode": "1729141272294",
            "gameRoleCode": "123C01",
            "gameRoleName": "角色名C01",
            "gameZoneCode": "17291412722943",
            "gameZoneName": "流沙河"
        }
    }
  }
)
Mock.mock(
  RegExp('.*/app/user/getGameRoleBound.*'),
  {
    "code": 0,
    "data": {
      "gameCode": "",
      "gameRoleCode": "101",
      "gameRoleName": "白木幽幽",
      "gameZoneCode": "111",
      "gameZoneName": "山高路远"
    },
    "message": "success"
  }
)

Mock.mock(
  RegExp('.*/app/auth/logout.*'),
  {
    "code": 0,
    "message": ""
  }
)


Mock.mock(
  RegExp('.*/app/user/listGameRoles.*'),
  // {"code":12003,"message":"無權限使用本系統","data":null}
  // { "code": 0, "message": "无数据", "data": null }
  {
    "code": 0,
    "message": "操作成功",
    "data": [
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123C01",
        "gameRoleName": "角色名C01",
        "gameRoleLevel": "80",
        "gameZoneCode": "17291412722943",
        "gameZoneName": "流沙河",
        "lastLogoutTime": 1726645019000,
        "lastActiveTime": 1726645019000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123A01",
        "gameRoleName": "角色名A01",
        "gameRoleLevel": "50",
        "gameZoneCode": "17291412722941",
        "gameZoneName": "花果山",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123A02",
        "gameRoleName": "角色名A02",
        "gameRoleLevel": "2",
        "gameZoneCode": "17291412722941",
        "gameZoneName": "花果山",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123B01",
        "gameRoleName": "角色名B01",
        "gameRoleLevel": "60",
        "gameZoneCode": "17291412722942",
        "gameZoneName": "高老庄",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123B02",
        "gameRoleName": "角色名B02",
        "gameRoleLevel": "12",
        "gameZoneCode": "17291412722942",
        "gameZoneName": "高老庄",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123B03",
        "gameRoleName": "角色名B03",
        "gameRoleLevel": "1",
        "gameZoneCode": "17291412722942",
        "gameZoneName": "高老庄",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      },
      {
        "gameCode": "1729141272294",
        "gameRoleCode": "123D01",
        "gameRoleName": "角色名D01",
        "gameRoleLevel": "10",
        "gameZoneCode": "17291412722944",
        "gameZoneName": "女儿国",
        "lastLogoutTime": 1726558515000,
        "lastActiveTime": 1726558515000
      }
    ]
  }
)

Mock.mock(
  RegExp('.*/app/user/bindGameRole.*'),
  {
    "code": 0,
    "message": ""
  }
)

Mock.mock(
  RegExp('.*/app/user/role/list.*'),
  {
    "code": 0,
    "message": "성공",
    "data": [
      {
        "roleCode": "1678096471052_littlehui",
        "userCode": "littlehui",
        "gameCode": "1678096471052",
        "gameName": "排球少年",
        "roleLevel": "12",
        "activeTime": 1718790063744,
        "gameZoneCode": "3",
        "gameZoneName": "第一区",
        "roleName": "littlehui"
      },
      {
        "roleCode": "1678096471052_littlehui2",
        "userCode": "littlehui2",
        "gameCode": "1678096471052",
        "gameName": "排球少年",
        "roleLevel": "12",
        "activeTime": 1718790063744,
        "gameZoneCode": "3",
        "gameZoneName": "第一区",
        "roleName": "littlehui2"
      }
    ]
  }
)