import { apiCommodityList, apiCreateOrder } from '@/api/mall';
import { generateRandomString, isMobile, tips } from '@/utils';
import { isIos } from '@/utils/constants';
import { logPurchaseClick, logPurchaseFail } from '@/utils/log';
import { getPayIconByName, isCanPayByLimitInfo } from '@/utils/pay';
import { setPayInfo } from '@/utils/storage';
import Decimal from 'decimal.js';

const state = {
  mallLoading: true,       // 商品列表loading
  orderPayLoading: false,   // 支付订单loading
  goodsList: [],
  goodsInfo: {},
  payInfo: {},
  payList: [],
  orderStatus: null    // 监听订单状态变化
}

const mutations = {
  SET_MALL_LOADING: (state, val) => {
    state.mallLoading = val
  },
  SET_ORDER_PAY_LOADING: (state, val) => {
    state.orderPayLoading = val
  },
  SET_GOODS_LIST: (state, arr) => {
    state.goodsList = arr
  },
  SET_GOODS_INFO: (state, info) => {
    state.goodsInfo = info
  },
  SET_PAY_INFO(state, info) {
    state.payInfo = info
  },
  SET_PAY_LIST(state, arr) {
    state.payList = arr
  },
  SET_ORDER_STATUS(state) {
    state.orderStatus = Date.now()
  }
}

const actions = {
  async getGoodsList({ state, commit, dispatch }) {
    commit('SET_MALL_LOADING', true)

    const result = await apiCommodityList()

    commit('SET_MALL_LOADING', false)

    if (result?.code === 0) {
      const listData = result.data

      commit('SET_GOODS_LIST', listData)

      // 打开订单弹窗时，同步最新商品内信息
      if(Object.keys(state.goodsInfo).length > 0) {
        const newGoodsInfo = listData.find(item => item.commodityCode === state.goodsInfo.commodityCode)

        newGoodsInfo && dispatch('selectGoods', newGoodsInfo)
      }
      
    }
  },
  selectGoods({ commit, dispatch, rootGetters }, info) {
    commit('SET_GOODS_INFO', info || {})
    commit('SET_PAY_INFO', rootGetters.payRecentlyUse || {})
    dispatch('countGoods', 1)
  },
  countGoods({ state, commit, rootGetters }, number) {
    if(!rootGetters.isSelectGoods) return

    const { goodsInfo } = state
    const { commoditySellPrice, payTypes } = goodsInfo
    const prizeOriginal = new Decimal(commoditySellPrice).mul(new Decimal(number))
    const prizeTotal = new Decimal(commoditySellPrice).mul(new Decimal(number))

    commit('SET_GOODS_INFO', {
      ...goodsInfo,
      prizeTotal,
      prizeOriginal,
      commodityNum: number,
      orderDedupeCode: generateRandomString(19) + Date.now()
    })

    const payTypesData = payTypes.map(({ paySubTypes, ...methodInfo }) => {

      paySubTypes = paySubTypes?.map(item => {
        return {
          ...item,
          img: getPayIconByName(item.payType?.toLocaleLowerCase())
        }
      }).filter(methodInfo => isCanPayByLimitInfo({ prizeTotal: prizeTotal.toNumber(), ...methodInfo }))

      return {
        ...methodInfo,
        paySubTypes,
        img: getPayIconByName(methodInfo.payType?.toLocaleLowerCase())
      }
    }).filter(typeInfo => isCanPayByLimitInfo({ prizeTotal:prizeTotal.toNumber(), ...typeInfo }))

    commit('SET_PAY_LIST', payTypesData)

  },
  createOrder({ state }) {
    const { commodityCode, commodityNum, promotionCode, prizeTotal, orderDedupeCode, commoditySellPrice, commodityCurrency } = state.goodsInfo
    const { id, ...payInfo } = state.payInfo

    logPurchaseClick({
      item_id: commodityCode,
      revenue: prizeTotal,
      price: commoditySellPrice,
      quantity: commodityNum,
      currency: commodityCurrency,
      coupon: null,
      method: id
    })

    return new Promise((resolve, reject) => {
      apiCreateOrder({
        commodityCode,
        commodityNum,
        orderDedupeCode,
        needPayPrice: prizeTotal,
        promotionCode,
        terminalType: isMobile() ? 'WAP' : 'WEB',
        osType: isMobile() ? isIos ? 'IOS' : 'ANDROID' : undefined,
        ...payInfo
      }).then(res => {
        if(res.code === 0) {
          resolve(res.data)
  
          // 该缓存给google埋点使用
          setPayInfo({
            item_id: commodityCode,
            revenue: prizeTotal,
            price: commoditySellPrice,
            quantity: commodityNum,
            currency: commodityCurrency,
            coupon: null,
            method: id
          })
  
        }else {
          reject()
  
          tips(res.message)
  
          logPurchaseFail(JSON.stringify(res))
        }
      }).catch(() => {
        reject()
      })
    })

  }
}

export default {
  namespaced: true,    // 命名空间，设置被 mapXxx 方法识别到
  state,
  mutations,
  actions
}

